import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Tooltip,
  useMap,
  CircleMarker,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import polyline from "@mapbox/polyline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../styles/RutaMoviles.css";

function ChangeMapView({ center, zoom }) {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return null;
}

const colorPalette = [
  "#FF5733",
  "#337BFF",
  "#33FF57",
  "#FF33A1",
  "#FFC300",
  "#8E44AD",
  "#16A085",
  "#D35400",
  "#C0392B",
  "#2C3E50",
];

const defaultIcon = L.icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.3/images/marker-icon.png",
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.3/images/marker-icon-2x.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.3/images/marker-shadow.png",
  shadowSize: [41, 41],
  shadowAnchor: [12, 41],
});

const houseIcon = L.divIcon({
  html: `<span style="font-size: 2rem;">🏠</span>`,
  className: "house-icon",
  iconSize: [40, 40],
  iconAnchor: [20, 20],
});

function parseLastReport(str) {
  const [datePart, timePart] = str.split(" ");
  const [day, month, year] = datePart.split("-");
  const [hours, minutes, seconds] = timePart.split(":");
  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return new Date(isoString);
}

function getDistanceFromLatLonInM(lat1, lon1, lat2, lon2) {
  const R = 6371000;
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function getCenterOfCoords(coords) {
  if (coords.length === 0) return null;
  let sumLat = 0,
    sumLon = 0;
  coords.forEach(([lat, lon]) => {
    sumLat += lat;
    sumLon += lon;
  });
  return [sumLat / coords.length, sumLon / coords.length];
}

const casaGeoJSON = {
  M50: [
    [-70.38410219793484, -23.579223898377734],
    [-70.38402571355262, -23.58037096031886],
    [-70.38188762741292, -23.580256254576],
    [-70.38200930711172, -23.579077328629737],
    [-70.3841056744977, -23.579223898377734],
  ],
  M61: [
    [-70.39466782023563, -23.66607065785516],
    [-70.39458044988294, -23.668164564372447],
    [-70.39065606487627, -23.668044532421177],
    [-70.39077255868006, -23.66592394977185],
    [-70.39467510109841, -23.66607732640051],
  ],
  M62: [
    [-70.38384415477127, -23.566383042897527],
    [-70.38364030688555, -23.56868418434732],
    [-70.38079716533011, -23.56844817169501],
    [-70.38115121692002, -23.565920842958192],
    [-70.38383342597855, -23.56634370682569],
  ],
};

function getCenterOfPolygon(polygon) {
  if (!polygon || polygon.length === 0) return null;
  let sumLat = 0,
    sumLon = 0;
  polygon.forEach(([lon, lat]) => {
    sumLon += lon;
    sumLat += lat;
  });
  return [sumLat / polygon.length, sumLon / polygon.length];
}

function isExcluded(point) {
  const excludedCenter = { latitude: -23.5526968, longitude: -70.3909427 };
  const dist = getDistanceFromLatLonInM(
    point.latitude,
    point.longitude,
    excludedCenter.latitude,
    excludedCenter.longitude
  );
  return dist <= 100;
}

function isInCasa(point, movil) {
  const polygon = casaGeoJSON[movil];
  if (!polygon) return false;
  return isPointInPolygon(point, polygon);
}

function isPointInPolygon(point, vs) {
  const x = point.longitude,
    y = point.latitude;
  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0],
      yi = vs[i][1];
    const xj = vs[j][0],
      yj = vs[j][1];
    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

function identifyStoppedSegments(data) {
  const distanceThreshold = 100;
  const timeThreshold = 300;
  const minorThreshold = 790;
  let segments = [];
  let currentGroup = [];
  const sortedData = [...data].sort(
    (a, b) =>
      parseLastReport(a.lastReport).getTime() -
      parseLastReport(b.lastReport).getTime()
  );
  for (let i = 0; i < sortedData.length; i++) {
    const point = sortedData[i];
    if (isExcluded(point)) {
      if (currentGroup.length > 1) {
        const startTime =
          parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
        const endTime =
          parseLastReport(currentGroup[currentGroup.length - 1].lastReport)
            .getTime() / 1000;
        const duration = endTime - startTime;
        if (duration >= timeThreshold) {
          segments.push({
            coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
            stopped: true,
            stopDurationSec: duration,
            minor: duration < minorThreshold,
          });
        }
      }
      currentGroup = [];
      continue;
    }
    if (currentGroup.length === 0) {
      currentGroup.push(point);
    } else {
      const lastPoint = currentGroup[currentGroup.length - 1];
      const dist = getDistanceFromLatLonInM(
        lastPoint.latitude,
        lastPoint.longitude,
        point.latitude,
        point.longitude
      );
      if (dist <= distanceThreshold) {
        currentGroup.push(point);
      } else {
        if (currentGroup.length > 1) {
          const startTime =
            parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
          const endTime =
            parseLastReport(currentGroup[currentGroup.length - 1].lastReport)
              .getTime() / 1000;
          const duration = endTime - startTime;
          if (duration >= timeThreshold) {
            segments.push({
              coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
              stopped: true,
              stopDurationSec: duration,
              minor: duration < minorThreshold,
            });
          }
        }
        currentGroup = [point];
      }
    }
  }
  if (currentGroup.length > 1) {
    const startTime =
      parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
    const endTime =
      parseLastReport(currentGroup[currentGroup.length - 1].lastReport)
        .getTime() / 1000;
    const duration = endTime - startTime;
    if (duration >= timeThreshold) {
      segments.push({
        coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
        stopped: true,
        stopDurationSec: duration,
        minor: duration < minorThreshold,
      });
    }
  }
  const totalStopTimeSec = segments
    .filter((seg) => !seg.minor)
    .reduce((acc, seg) => acc + seg.stopDurationSec, 0);
  const stopTimeTotal = Math.floor(totalStopTimeSec / 60);
  return { stopTimeTotal, stopTimeSec: totalStopTimeSec, segments };
}

function identifyCasaStops(data) {
  if (!data || data.length === 0) return [];
  let stops = [];
  let currentGroup = [];
  const sortedData = [...data].sort(
    (a, b) =>
      parseLastReport(a.lastReport).getTime() -
      parseLastReport(b.lastReport).getTime()
  );
  for (let i = 0; i < sortedData.length; i++) {
    const point = sortedData[i];
    if (currentGroup.length === 0) {
      currentGroup.push(point);
    } else {
      const lastPoint = currentGroup[currentGroup.length - 1];
      const dist = getDistanceFromLatLonInM(
        lastPoint.latitude,
        lastPoint.longitude,
        point.latitude,
        point.longitude
      );
      const timeDiff =
        (parseLastReport(point.lastReport).getTime() -
          parseLastReport(lastPoint.lastReport).getTime()) /
        1000;
      if (dist <= 100 && timeDiff <= 7200) {
        currentGroup.push(point);
      } else {
        const startTime =
          parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
        const endTime =
          parseLastReport(currentGroup[currentGroup.length - 1].lastReport)
            .getTime() / 1000;
        stops.push({
          coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
          stopDurationSec: endTime - startTime,
          start: currentGroup[0].lastReport,
          end: currentGroup[currentGroup.length - 1].lastReport,
        });
        currentGroup = [point];
      }
    }
  }
  if (currentGroup.length > 0) {
    const startTime =
      parseLastReport(currentGroup[0].lastReport).getTime() / 1000;
    const endTime =
      parseLastReport(currentGroup[currentGroup.length - 1].lastReport)
        .getTime() / 1000;
    stops.push({
      coords: currentGroup.map((pt) => [pt.latitude, pt.longitude]),
      stopDurationSec: endTime - startTime,
      start: currentGroup[0].lastReport,
      end: currentGroup[currentGroup.length - 1].lastReport,
    });
  }
  return stops;
}

function filterClosePoints(coords) {
  const threshold = 0.0001;
  return coords.filter((point, index, array) => {
    if (index === 0) return true;
    const prev = array[index - 1];
    const lonDiff = Math.abs(point[0] - prev[0]);
    const latDiff = Math.abs(point[1] - prev[1]);
    return latDiff > threshold || lonDiff > threshold;
  });
}

function chunkArray(arr, size) {
  const results = [];
  for (let i = 0; i < arr.length; i += size) {
    results.push(arr.slice(i, i + size));
  }
  return results;
}

async function getORSRoute(coords) {
  let allCoords = [];
  let totalDistance = 0;
  const chunks = chunkArray(coords, 69);
  for (const chunk of chunks) {
    const reqBody = JSON.stringify({ coordinates: chunk });
    const orsResp = await fetch(
      "https://api.openrouteservice.org/v2/directions/driving-car",
      {
        method: "POST",
        mode: "cors",
        headers: {
          Accept:
            "application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8",
          Authorization:
            "5b3ce3597851110001cf6248a76a43fc76e94cf4aa29b2b6b33dd538",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: reqBody,
      }
    );
    if (!orsResp.ok) {
      throw new Error(`Error HTTP ORS: ${orsResp.status}`);
    }
    const orsData = await orsResp.json();
    if (
      orsData.routes &&
      orsData.routes.length > 0 &&
      orsData.routes[0].geometry &&
      orsData.routes[0].summary
    ) {
      const decoded = polyline.decode(orsData.routes[0].geometry);
      allCoords = [...allCoords, ...decoded];
      totalDistance += orsData.routes[0].summary.distance;
    }
  }
  return {
    decodedCoords: allCoords,
    distanceKm: totalDistance / 1000,
  };
}

const RutaMoviles = () => {
  const [routes, setRoutes] = useState([]);
  const [progress, setProgress] = useState(100);
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalFuel, setTotalFuel] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [timeOption, setTimeOption] = useState("allDay");
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");
  const [availableMoviles, setAvailableMoviles] = useState([]);
  const [selectedMoviles, setSelectedMoviles] = useState([]);

  const [selectedCity, setSelectedCity] = useState("Antofagasta");
  const [mapCenter, setMapCenter] = useState([-23.62, -70.39]);
  const [mapZoom, setMapZoom] = useState(12);

  useEffect(() => {
    const fetchMoviles = async () => {
      try {
        const response = await fetch(
          "https://pedidos.ayvgas.cl/ubicaciones/get_json_files.php"
        );
        if (!response.ok) {
          throw new Error("Error al cargar los móviles.");
        }
        const data = await response.json();
        const mobiles = Array.isArray(data) ? data : Object.values(data);
        setAvailableMoviles(mobiles);
      } catch (error) {
        console.error("Error al obtener los móviles:", error);
      }
    };
    fetchMoviles();
  }, []);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedMoviles((prev) => [...prev, value]);
    } else {
      setSelectedMoviles((prev) => prev.filter((m) => m !== value));
    }
  };

  const formatLocalDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const toggleVisibility = (mobile) => {
    setRoutes((prevRoutes) =>
      prevRoutes.map((route) =>
        route.mobile === mobile
          ? { ...route, visible: !route.visible }
          : route
      )
    );
  };

  const handleFilter = async () => {
    try {
      if (selectedMoviles.length === 0) {
        alert("Por favor, selecciona al menos un móvil.");
        return;
      }
      setLoading(true);
      setError(null);
      setRoutes([]);
      setProgress(100);
      setTotalDistance(0);
      setTotalFuel(0);

      let newRoutes = [];
      let colorIndex = 0;
      let distanceSum = 0;
      const timeParams =
        timeOption === "allDay"
          ? { startTime: "00:00", endTime: "23:59" }
          : { startTime, endTime };

      const startDateStr = formatLocalDate(startDate);
      const endDateStr = formatLocalDate(endDate);

      for (const movilSeleccionado of selectedMoviles) {
        const body = {
          movil: movilSeleccionado,
          startDate: startDateStr,
          endDate: endDateStr,
          timeOption,
          ...timeParams,
        };

        console.log("Enviando al PHP:", body);

        const response = await fetch(
          "https://pedidos.ayvgas.cl/ubicaciones/get_ruta_filtrada.php",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          }
        );
        if (!response.ok) {
          throw new Error(`Error HTTP PHP: ${response.status}`);
        }
        const filteredData = await response.json();
        console.log("Móvil:", movilSeleccionado, "Registros:", filteredData);
        if (filteredData.length === 0) {
          console.warn(`No hay registros para el móvil ${movilSeleccionado}`);
          continue;
        }

        const sortedData = [...filteredData].sort(
          (a, b) =>
            parseLastReport(a.lastReport).getTime() -
            parseLastReport(b.lastReport).getTime()
        );

        let earliestExit = null;
        for (let i = 0; i < sortedData.length; i++) {
          if (!isExcluded(sortedData[i])) {
            earliestExit = parseLastReport(sortedData[i].lastReport);
            break;
          }
        }

        let latestEntry = null;
        for (let i = 1; i < sortedData.length; i++) {
          const prevExcluded = isExcluded(sortedData[i - 1]);
          const currentExcluded = isExcluded(sortedData[i]);
          if (!prevExcluded && currentExcluded) {
            const entryTime = parseLastReport(sortedData[i].lastReport);
            if (!latestEntry || entryTime > latestEntry) {
              latestEntry = entryTime;
            }
          }
        }

        let totalTimeSecAdjusted = 0;
        for (let i = 0; i < sortedData.length - 1; i++) {
          const gap =
            (parseLastReport(sortedData[i + 1].lastReport).getTime() -
              parseLastReport(sortedData[i].lastReport).getTime()) /
            1000;
          if (gap < 7200) {
            totalTimeSecAdjusted += gap;
          }
        }

        let excludedTimeSecAdjusted = 0;
        for (let i = 0; i < sortedData.length - 1; i++) {
          const gap =
            (parseLastReport(sortedData[i + 1].lastReport).getTime() -
              parseLastReport(sortedData[i].lastReport).getTime()) /
            1000;
          if (
            gap < 7200 &&
            isExcluded(sortedData[i]) &&
            isExcluded(sortedData[i + 1])
          ) {
            excludedTimeSecAdjusted += gap;
          }
        }

        const casaData = filteredData.filter((d) =>
          isInCasa(d, movilSeleccionado)
        );
        const generalData = filteredData.filter(
          (d) => !isInCasa(d, movilSeleccionado)
        );

        const { stopTimeTotal, stopTimeSec, segments } =
          identifyStoppedSegments(generalData);

        const casaStops = identifyCasaStops(casaData);
        const casaTotalTimeSec = casaStops.reduce(
          (sum, stop) => sum + stop.stopDurationSec,
          0
        );
        const casaTotal = Math.floor(casaTotalTimeSec / 60);

        const activeTimeSec =
          totalTimeSecAdjusted -
          excludedTimeSecAdjusted -
          (stopTimeSec + casaTotalTimeSec);
        const activeTime = Math.floor(activeTimeSec / 60);

        let coordsForORS = filteredData.map((d) => [d.longitude, d.latitude]);
        coordsForORS = filterClosePoints(coordsForORS);
        const { decodedCoords, distanceKm } = await getORSRoute(coordsForORS);
        distanceSum += distanceKm;

        const casaCoord =
          casaTotal > 0
            ? getCenterOfPolygon(casaGeoJSON[movilSeleccionado])
            : null;

        const color = colorPalette[colorIndex % colorPalette.length];
        colorIndex++;

        newRoutes.push({
          mobile: movilSeleccionado,
          color,
          distanceKm: distanceKm.toFixed(2),
          stopTime: stopTimeTotal,
          decodedCoords,
          segments,
          casa: casaTotal,
          casaCoord,
          casaStops,
          activeTime,
          exitTime: earliestExit,
          entryTime: latestEntry,
          visible: true,
        });
      }

      setRoutes(newRoutes);
      setTotalDistance(distanceSum.toFixed(2));
      setTotalFuel((distanceSum / 7.4).toFixed(2));
      setLoading(false);

      if (selectedCity === "Arica") {
        setMapCenter([-18.4783, -70.3126]);
        setMapZoom(13);
      } else {
        setMapCenter([-23.62, -70.39]);
        setMapZoom(12);
      }
    } catch (err) {
      console.error("Error en handleFilter:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  const getPartialCoords = (decodedCoords) => {
    if (progress >= 100) return decodedCoords;
    const numPoints = Math.floor((decodedCoords.length * progress) / 100);
    if (numPoints < 1) return [decodedCoords[0]];
    return decodedCoords.slice(0, numPoints);
  };

  return (
    <div className="container">
      <div
        className="filter-panel"
        style={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 20px)",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>{`
          .filter-panel::-webkit-scrollbar {
            display: none;
          }
        `}</style>
        <h4>Filtros</h4>
        <div className="filter-group filter-city-group">
          <label>Ciudad:</label>
          <select
            value={selectedCity}
            onChange={(e) => {
              const city = e.target.value;
              setSelectedCity(city);
              if (city === "Arica") {
                setMapCenter([-18.4783, -70.3126]);
                setMapZoom(13);
              } else {
                setMapCenter([-23.62, -70.39]);
                setMapZoom(12);
              }
            }}
            className="input city-select"
          >
            <option value="Antofagasta">Antofagasta</option>
            <option value="Arica">Arica</option>
          </select>
        </div>
        <div className="filter-group filter-date-group">
          <label>Fecha:</label>
          <div className="date-picker-group">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              dateFormat="yyyy-MM-dd"
              className="input date-input"
              withPortal
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              minDate={startDate}
              dateFormat="yyyy-MM-dd"
              className="input date-input"
              withPortal
            />
          </div>
        </div>
        <div className="filter-group filter-hour-group">
          <label>Hora:</label>
          <select
            value={timeOption}
            onChange={(e) => setTimeOption(e.target.value)}
            className="input hour-select"
          >
            <option value="allDay">Todo el día</option>
            <option value="range">Rango Específico</option>
          </select>
          {timeOption === "range" && (
            <div className="time-group hour-range-group">
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="input time-input start-time-input"
              />
              <span className="time-separator"> - </span>
              <input
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="input time-input end-time-input"
              />
            </div>
          )}
        </div>
        <div className="filter-group filter-mobiles-group">
          <label>Móviles:</label>
          <div className="mobiles-checkbox-group">
            {availableMoviles.map((movil, index) => (
              <div key={index} className="checkbox-item">
                <input
                  type="checkbox"
                  id={`movil-${index}`}
                  value={movil}
                  onChange={handleCheckboxChange}
                  checked={selectedMoviles.includes(movil)}
                />
                <label htmlFor={`movil-${index}`}>{movil}</label>
              </div>
            ))}
          </div>
        </div>
        <button className="btn filter-btn" onClick={handleFilter}>
          Mostrar
        </button>
        <div className="progress-section">
          <div className="progress-bar">
            <label>Progreso:</label>
            <input
              type="range"
              min="0"
              max="100"
              step="1"
              value={progress}
              onChange={(e) => setProgress(Number(e.target.value))}
              style={{ width: "200px", marginLeft: "10px" }}
            />
            <span style={{ marginLeft: "10px" }}>{progress}%</span>
          </div>
          <div className="mobile-details">
            <div className="mobile-card">
              <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Resumen General:
              </p>
              <p>
                Distancia Total: {totalDistance} km | Consumo Total:{" "}
                {totalFuel} litros
              </p>
            </div>
            {routes.map((r, index) => (
              <div className="mobile-card" key={index}>
                <p style={{ marginBottom: "5px" }}>
                  Móvil {r.mobile}:
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      fontSize: "1.2rem",
                    }}
                    onClick={() => toggleVisibility(r.mobile)}
                    title={r.visible ? "Ocultar ruta" : "Mostrar ruta"}
                  >
                    {r.visible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </p>
                <p>
                  Distancia: {r.distanceKm} km | Consumo:{" "}
                  {(r.distanceKm / 7.4).toFixed(2)} litros
                </p>
                <p>
                  <strong>Detenido (General):</strong> {r.stopTime} min
                </p>
                <p>
                  <strong>Casa (Total):</strong> {r.casa} min
                </p>
                <p>
                  <strong>Tiempo activo:</strong> {r.activeTime} min
                </p>
                {r.exitTime && (
                  <p>
                    <strong>Salida Local:</strong>{" "}
                    {r.exitTime.toLocaleTimeString()}
                  </p>
                )}
                {r.entryTime && (
                  <p>
                    <strong>Entrada Local:</strong>{" "}
                    {r.entryTime.toLocaleTimeString()}
                  </p>
                )}
                {r.casa > 0 && r.casaStops && (
                  <div style={{ fontSize: "0.9em", marginTop: "5px" }}>
                    <p>
                      <em>Detención en Casa detallada:</em>
                    </p>
                    {r.casaStops
                      .filter(
                        (stop) =>
                          Math.floor(stop.stopDurationSec / 60) > 0
                      )
                      .map((stop, idx) => {
                        const durationMin = Math.floor(
                          stop.stopDurationSec / 60
                        );
                        return (
                          <p key={idx}>
                            Stop {idx + 1}: {durationMin} min (Inicio:{" "}
                            {stop.start}, Fin: {stop.end})
                          </p>
                        );
                      })}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="map-container">
        <MapContainer
          center={mapCenter}
          zoom={mapZoom}
          style={{ height: "100%", width: "100%" }}
        >
          <ChangeMapView center={mapCenter} zoom={mapZoom} />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {routes.filter(route => route.visible).map((routeObj, idx) => {
            const partialORS = getPartialCoords(routeObj.decodedCoords);
            return (
              <React.Fragment key={idx}>
                <Polyline
                  positions={partialORS}
                  pathOptions={{
                    color: routeObj.color,
                    weight: 4,
                    opacity: 0.9,
                  }}
                >
                  <Tooltip sticky>
                    <div style={{ textAlign: "center" }}>
                      <strong>Móvil {routeObj.mobile}</strong>
                      <p>Distancia: {routeObj.distanceKm} km</p>
                      <p>Consumo: {(routeObj.distanceKm / 7.4).toFixed(2)} L</p>
                    </div>
                  </Tooltip>
                </Polyline>
                {routeObj.segments.map((seg, i) => {
                  if (!seg.stopped) return null;
                  const center = getCenterOfCoords(seg.coords);
                  if (!center) return null;
                  const durationMin =
                    isNaN(seg.stopDurationSec) ||
                    seg.stopDurationSec === undefined
                      ? 0
                      : Math.floor(seg.stopDurationSec / 60);
                  const markerColor = seg.minor ? "#87CEFA" : "black";
                  return (
                    <CircleMarker
                      key={`general-${i}`}
                      center={center}
                      radius={8}
                      pathOptions={{
                        color: markerColor,
                        fillColor: markerColor,
                        fillOpacity: 0.8,
                      }}
                    >
                      <Tooltip>
                        <div style={{ textAlign: "center" }}>
                          <strong>
                            Detención general{seg.minor ? " (menor)" : ""}
                          </strong>
                          <p>{durationMin} min</p>
                        </div>
                      </Tooltip>
                    </CircleMarker>
                  );
                })}
                {routeObj.casa > 0 && routeObj.casaCoord && (
                  <Marker position={routeObj.casaCoord} icon={houseIcon}>
                    <Tooltip>
                      <div style={{ textAlign: "center" }}>
                        <strong>Detención en Casa</strong>
                        {routeObj.casaStops && routeObj.casaStops.length > 0 ? (
                          routeObj.casaStops
                            .filter(
                              (stop) =>
                                Math.floor(stop.stopDurationSec / 60) > 0
                            )
                            .map((stop, index) => {
                              const durationMin = Math.floor(
                                stop.stopDurationSec / 60
                              );
                              return (
                                <p key={index}>
                                  Stop {index + 1}: {durationMin} min (Inicio:{" "}
                                  {stop.start}, Fin: {stop.end})
                                </p>
                              );
                            })
                        ) : (
                          <p>{routeObj.casa} min</p>
                        )}
                      </div>
                    </Tooltip>
                  </Marker>
                )}
                {partialORS.length > 0 && (
                  <Marker position={partialORS[0]} icon={defaultIcon} />
                )}
                {partialORS.length > 1 && (
                  <Marker
                    position={partialORS[partialORS.length - 1]}
                    icon={defaultIcon}
                  />
                )}
              </React.Fragment>
            );
          })}
        </MapContainer>
      </div>
      {loading && <p style={{ textAlign: "center" }}>Cargando rutas...</p>}
      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
    </div>
  );
};
export default RutaMoviles;
